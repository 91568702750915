.container {
    width: 100%;
    max-width: 1000px;
    height: 100vh;
    margin: 0 auto;
    text-align: center;
  }
  .container footer {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    font-family: "Lato", sans-serif;
  }
  
  .container main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-family: "Lato", sans-serif;
    margin-top: -35px;
  }
  .container main h1 {
    font-weight: 400;
    text-transform: uppercase;
    color: #8e8883;
  }
  
  .socialmenu {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
  }
  .socialmenu a {
    font-size: 40px;
    text-decoration: none;
    color: #743f6d;
    transition: all 0.2s;
  }
  .socialmenu a:hover {
    color: #883a7f;
  }