@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --subtle-pattern: url("./assets/img/topography.webp");

    --primary-color: #2b6777;
    --secondary-color: #52ab98;
    --tertiary-color: #c8d8e4;
    
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

html { scroll-behavior: smooth; } 
body { background-image: var(--subtle-pattern);}

main{ min-height: 2250px;}

#innerContent {
    position: relative;
    height: auto;
}

.container  {
    width: 100%;
    max-width: 960px;
    margin: auto;
}

.site-content.shift{
    transition: all 1s ;
}
.site-content{
    width: calc( 100% - 80px);
    transform: translateX(80px);
}

.site-content .section {
    padding: 6rem 0;
}

.headline {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}
.subhead {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.body-copy{
    font-size: 1rem;
    line-height: 1.6rem;
    margin-bottom: 1.2rem;
}

/* InnerContent Navigation */

.site-nav{
    position: absolute;
    top: 0; left: 0;
    height: 100vh;
    width: 80px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    transition: all 0.5s ease-in-out;
    opacity: 0;
}

.site-nav.sticky{
    position: fixed;
    z-index: 100;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    opacity: 1;
}

.site-nav .site-branding{
    padding: 20px;
}
.site-nav ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    justify-content: center;
    height: 100%;
}
.site-nav ul a {
    padding: 0;
    color: #000;
    text-decoration: none;
    text-align: center;
    position: relative;
}
.site-nav ul a .hover-icon, .site-nav ul a .icon-text {
    display: block;
}

.site-nav ul a .icon-text {
    background-color: var(--primary-color);
    color: #fff;
    padding: 2px 5px;
    font-size: 10px;
    border-radius: 20px;
    margin: 10px 0 0 0;
    position: relative;
    transform: translateY(5px);
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.site-nav ul a .icon-text::before {
    content: "";
    position: absolute;
    top: -7px;
    left: 50%;
    width: 0;
    border-bottom: 10px solid var(--primary-color);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    transform: translateX(-50%);
}
.site-nav ul a:hover .icon-text {
    transform: translateY(0);
    opacity: 1;
}


.nav__list .site-branding{
    font-size: 30px;
    line-height: 25px;
    font-weight: bold;
    margin-top: -23px;
    margin-left: 15px;
    color: var(--primary-color);
}


@media (max-width: 767px) {
    .App {
        padding: 15px;
    }
    .home-nav.sticky{
        position: fixed;
        width: 100%;
        background-color: #FFF;
        z-index: 10;
        top: 0; left: 0;
        box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
    }

    .site-nav{ display: none;}

    .site-content {
        width: 90%;
        transform: translateX(0);
        margin: auto;
    }

    main{ min-height: 5150px;}
    
}

@media (min-width: 768px) and (max-width: 1023px) {
    .site-content {
        width: 90%;
        transform: translateX(0);
        margin: 0 auto;
    }
    .home-nav.sticky{
        position: fixed;
        width: 100%;
        margin-left: -40px;
        background-color: #FFF;
        z-index: 10;
        top: 0;
    }

    .site-nav{ display: none;}
}

@media (min-width: 1024px) {
    .nav__list .site-branding{
        display: none;
    }
}
    