.portfolioCard{
    width: calc((100% - 40px) / 3);
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #dcdcdc;
}
.portfolioCard img{
    width: 100%;
}

.portfolioCard__text{
    padding: 20px;
}

.portfolioCard__links{
    display: flex;
    column-gap: 20px;
    justify-content: center;
    margin: 10px auto;
}
.portfolioCard__links a{
    text-decoration: none;
    background-color: var(--primary-color);
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 12px;
    transition: all .5s;
}
.portfolioCard__links a:hover{
    background-color: #5996a6;
}

.portfolioCard.box-shadow{
    transition: all .5s;
}

.portfolioCard.box-shadow:hover{
    box-shadow: rgba(0, 0, 0, 0.50) 0px 3px 8px;
}

@media (max-width: 767px){
    .portfolioCard{
        width: 100%;
       
    }
}
    
