/* Page: About */

#about {
    height: auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: left;
}

.about-content{
    display: flex;
    column-gap: 20px;
}
.about-content img{
    align-self: flex-end;
}

.myskills {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    list-style: none;
    line-height: 1.2rem;
}

.myskills li {
    margin-bottom: 10px;
    font-size: 1rem;
}

@media (max-width: 600px) {
    .about-content{
        flex-direction: column-reverse;
    }
    .about-content img{
        width: 100%; margin-bottom: 20px;
    }
    .myskills {
        display: grid;
        grid-template-columns: 50% 50%;
    }
}