@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
    --subtle-pattern: url(/static/media/topography.fb9a7da3.webp);

    --primary-color: #2b6777;
    --secondary-color: #52ab98;
    --tertiary-color: #c8d8e4;
    
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

html { scroll-behavior: smooth; } 
body { background-image: url(/static/media/topography.fb9a7da3.webp); background-image: var(--subtle-pattern);}

main{ min-height: 2250px;}

#innerContent {
    position: relative;
    height: auto;
}

.container  {
    width: 100%;
    max-width: 960px;
    margin: auto;
}

.site-content.shift{
    transition: all 1s ;
}
.site-content{
    width: calc( 100% - 80px);
    -webkit-transform: translateX(80px);
            transform: translateX(80px);
}

.site-content .section {
    padding: 6rem 0;
}

.headline {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}
.subhead {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.body-copy{
    font-size: 1rem;
    line-height: 1.6rem;
    margin-bottom: 1.2rem;
}

/* InnerContent Navigation */

.site-nav{
    position: absolute;
    top: 0; left: 0;
    height: 100vh;
    width: 80px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 20px;
    -webkit-align-items: center;
            align-items: center;
    transition: all 0.5s ease-in-out;
    opacity: 0;
}

.site-nav.sticky{
    position: fixed;
    z-index: 100;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    opacity: 1;
}

.site-nav .site-branding{
    padding: 20px;
}
.site-nav ul {
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-row-gap: 30px;
    row-gap: 30px;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
}
.site-nav ul a {
    padding: 0;
    color: #000;
    text-decoration: none;
    text-align: center;
    position: relative;
}
.site-nav ul a .hover-icon, .site-nav ul a .icon-text {
    display: block;
}

.site-nav ul a .icon-text {
    background-color: #2b6777;
    background-color: var(--primary-color);
    color: #fff;
    padding: 2px 5px;
    font-size: 10px;
    border-radius: 20px;
    margin: 10px 0 0 0;
    position: relative;
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.site-nav ul a .icon-text::before {
    content: "";
    position: absolute;
    top: -7px;
    left: 50%;
    width: 0;
    border-bottom: 10px solid #2b6777;
    border-bottom: 10px solid var(--primary-color);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.site-nav ul a:hover .icon-text {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
}


.nav__list .site-branding{
    font-size: 30px;
    line-height: 25px;
    font-weight: bold;
    margin-top: -23px;
    margin-left: 15px;
    color: #2b6777;
    color: var(--primary-color);
}


@media (max-width: 767px) {
    .App {
        padding: 15px;
    }
    .home-nav.sticky{
        position: fixed;
        width: 100%;
        background-color: #FFF;
        z-index: 10;
        top: 0; left: 0;
        box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
    }

    .site-nav{ display: none;}

    .site-content {
        width: 90%;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        margin: auto;
    }

    main{ min-height: 5150px;}
    
}

@media (min-width: 768px) and (max-width: 1023px) {
    .site-content {
        width: 90%;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        margin: 0 auto;
    }
    .home-nav.sticky{
        position: fixed;
        width: 100%;
        margin-left: -40px;
        background-color: #FFF;
        z-index: 10;
        top: 0;
    }

    .site-nav{ display: none;}
}

@media (min-width: 1024px) {
    .nav__list .site-branding{
        display: none;
    }
}
    
/* Page: About */

#about {
    height: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: left;
}

.about-content{
    display: -webkit-flex;
    display: flex;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}
.about-content img{
    -webkit-align-self: flex-end;
            align-self: flex-end;
}

.myskills {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    list-style: none;
    line-height: 1.2rem;
}

.myskills li {
    margin-bottom: 10px;
    font-size: 1rem;
}

@media (max-width: 600px) {
    .about-content{
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
    }
    .about-content img{
        width: 100%; margin-bottom: 20px;
    }
    .myskills {
        display: grid;
        grid-template-columns: 50% 50%;
    }
}
#home {
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    /* background-image: var(--subtle-pattern); */
}

#home h1 {
    font-size: 65px;
    text-transform: uppercase;
    font-weight: 800;
    margin: 0;
    margin-bottom: 10px;
    color: var(--primary-color);
}
#home p{
    font-size: 20px;
    font-weight: 400;
    max-width: 700px;
    margin-bottom: 20px;
}
#home .nav__list{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    list-style: none;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    margin-top: 30px;
}
#home .nav__list a{
    text-decoration: none;
    color: #000;
    text-align: center;
    
}

#home .nav__list a .hover-icon{
    color: var(--primary-color);
}

#home .nav__list a .icon-text{
    background-color: var(--primary-color);
    color: #fff;
    padding:2px 5px;
    font-size: 10px;
    border-radius: 20px;
}
#home .nav__list a .hover-icon, #home .nav__list a .icon-text{
    transition: all .5s ease-in-out;
}
#home .nav__list a .icon-text{
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    display: block;
}
#home .nav__list a .icon-text::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 50%;
    width: 0;
    border-bottom: 10px solid var(--primary-color);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
#home .nav__list a:hover .icon-text{
   opacity: 1;-webkit-transform: translateY(8px);transform: translateY(8px);
}
#home .nav__list a .icon-text{
    display: block;
}


.scroll__down{
    position:absolute;
    bottom:2.5rem;
    left: 0;
    width: 100%;
  }
.scroll__down a{
    text-decoration: none;
  }
  
  .home__scroll-name{
    font-size: 12px; text-decoration: none; color: var(--primary-color);
  }

  
  
  .mouse{
    border: 2px solid var(--primary-color);
    display: block;
    height: 1.6rem;
    width: 1.25rem;
    margin: auto;
    margin-top: .75rem;
    border-radius: 1rem;
    position: relative;
  }
  
  .wheel{
    background-color: var(--primary-color);
    border-radius: 100%;
    width: 0.25rem;
    height: 0.25rem;
    position: absolute;
    top: .5rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-animation: ani-mouse 2s linear infinite;
            animation: ani-mouse 2s linear infinite;
  }
  
  @-webkit-keyframes ani-mouse {
    0%{
     top: 29%;
    }
    15%{
     top: 50%;
    }
    50%{
     top: 50%;
    }
    100%{
     top: 29%;
    }
   
  }
  
  @keyframes ani-mouse {
    0%{
     top: 29%;
    }
    15%{
     top: 50%;
    }
    50%{
     top: 50%;
    }
    100%{
     top: 29%;
    }
   
  }





@media (max-width: 600px) {

    #home h1 {
        line-height: 60px;
    }

    #home .nav__list {
        grid-column-gap: 10px;
        -webkit-column-gap: 10px;
                column-gap: 10px;
    }

    #home .nav__list a .hover-icon {
        font-size: 25px;}
    #home .nav__list a .icon-text {
        font-size: 16px;}
        .home__content {
            margin-top: calc( 230px - 100%);
        }
}

  
.portfolioGrid{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
    max-width: 960px;
    margin: 20px auto 50px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-row-gap: 20px;
    row-gap: 20px;
}

.portfolioMenu{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* max-width: 960px;
    margin: 0 auto; */
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}
.portfolioCard{
    width: calc((100% - 40px) / 3);
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #dcdcdc;
}
.portfolioCard img{
    width: 100%;
}

.portfolioCard__text{
    padding: 20px;
}

.portfolioCard__links{
    display: -webkit-flex;
    display: flex;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 10px auto;
}
.portfolioCard__links a{
    text-decoration: none;
    background-color: var(--primary-color);
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 12px;
    transition: all .5s;
}
.portfolioCard__links a:hover{
    background-color: #5996a6;
}

.portfolioCard.box-shadow{
    transition: all .5s;
}

.portfolioCard.box-shadow:hover{
    box-shadow: rgba(0, 0, 0, 0.50) 0px 3px 8px;
}

@media (max-width: 767px){
    .portfolioCard{
        width: 100%;
       
    }
}
    

.container {
    width: 100%;
    max-width: 1000px;
    height: 100vh;
    margin: 0 auto;
    text-align: center;
  }
  .container footer {
    position: absolute;
    bottom: 25px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-family: "Lato", sans-serif;
  }
  
  .container main {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    font-family: "Lato", sans-serif;
    margin-top: -35px;
  }
  .container main h1 {
    font-weight: 400;
    text-transform: uppercase;
    color: #8e8883;
  }
  
  .socialmenu {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
  .socialmenu a {
    font-size: 40px;
    text-decoration: none;
    color: #743f6d;
    transition: all 0.2s;
  }
  .socialmenu a:hover {
    color: #883a7f;
  }
